import React from 'react';
import { Timeline } from 'react-twitter-widgets';
import { translate } from 'react-switch-lang';
import { Link } from "react-router-dom";
import { Layout, Header, Footer, Content } from "../../layout";
import { Button, LanguageSwitcher } from "../../components";

import paths  from '../../paths';

class LatestNews extends React.Component {

    twitterAccounts = ['spietikainen', 'HennaVirkkunen', 'petrisarvamaa', 'alviinaalametsa'];

    render() {
        return (
            <Layout bg="3">
            <LanguageSwitcher/>
            <Header>
                <Button classes="-rounded -blueFill">
                    <Link to={paths.base}>{this.props.t('back')}</Link>
                </Button>
            </Header>
            <Content>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    {this.twitterAccounts.map( (account) => {
                        return(
                            <Timeline
                            dataSource={{
                                sourceType: 'profile',
                                screenName: account
                            }}
                            options={{
                                chrome: "noheader, nofooter, transparent, noborders, noscrollbar",
                                height: '600',
                                width: '400'
                            }}
                            />     
                        );
                    })}
                </div>
                
            </Content>
            <Footer>
            </Footer>
        </Layout>

        )
    }
}

export default translate(LatestNews);