

const base = '/2037136634'

const paths = {
    base: base + '/',

    euInfo: base + '/eu-info',
    euInfoSingle: base + '/eu-info/single',

    quiz: base + '/quiz',
    latestNews: base + '/latest-news',

};

export const baseAPIURL = 'https://admin.eu-information.jakob-reg.fi/wp-json/wp/v2';
export const mobileQuizURL = 'https://eu-information.jakob-reg.fi/quiz';


export default paths;