import React from 'react';
import { Link, Redirect } from "react-router-dom";
import { Layout, Header, Footer, Content } from "../../layout";
import { Button, MoreInfo, LanguageSwitcher } from "../../components";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { translate } from 'react-switch-lang';

import paths  from '../../paths';

import "../../styles/views/eu-info-pages/eu-info-pages.scss";

// Taxonomy IDs from wordpress
var pageTypeMap = {
  2: 'normal',
  3: 'map',
  11: 'modals',
  12: 'buttons'
}

class EUInformationSingle extends React.Component {

  // Page data passed with Link 
    constructor( props ) {
      super(props);

      if ( props.location.state !== undefined ) {
        this.page = props.location.state.page === undefined ? false : props.location.state.page;
      } else {
        this.page = false;
      }

    }

    getPageType() {
      let pageType = pageTypeMap[this.page['eu-information-page-type']];
      return pageType === undefined ? false: pageType;
    }



    render() {
      if ( this.page === undefined || this.page.eu_info_fields === null || this.page.eu_info_fields === undefined ) {
        return( <Redirect to={paths.euInfo} /> );
      }
      return (
        <Layout bg="1">
          <LanguageSwitcher/>
          <Header>
              <Button classes="-rounded -blueFill">
                <Link to={paths.euInfo}>{this.props.t('back')}</Link>
              </Button>
              <h4 style={{marginLeft: '1.5rem'}} className="wm-u-fw400 wm-u-cBlue"> - {this.page.title.rendered}</h4>
          </Header>
          <Content>
            <MainContent {...this.props} page={this.page} pageType={this.getPageType()} />
          </Content>
          <Footer absolute={true}>
              { this.page.eu_info_fields.more_info ? 

                  <MoreInfo text={this.props.t('moreInfo')} link={this.page.eu_info_fields.more_info}/>
                  :
                  <div></div>
              }

          </Footer>
        </Layout>
      )
    }
    
}
export default translate(EUInformationSingle);

function MainContent(props) {
  if ( ! props.pageType ) {
    console.error('page type missing');
    return( <Redirect to={paths.euInfo} /> );
  }

  if ( props.pageType === 'map' ) {
    return <Map {...props} />
  }
  else if ( props.pageType === 'modals' ) {
    return <Modals {...props} />
  }
  else if ( props.pageType === 'buttons' ) {
    return <Buttons {...props} />
  }
  else {
    return <Normal {...props} />
  }
}


function Normal(props) {

  let slickSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: props.page.eu_info_fields.columns_per_page,
    slidesToScroll: 1
  };

  return (
    <div className={`wm-infoCarousel -slidesPerPage${props.page.eu_info_fields.columns_per_page}`}>
      
      { props.page.eu_info_fields.eu_info_columns !== undefined ?
        <Slider {...slickSettings}>
        {props.page.eu_info_fields.eu_info_columns.map( (column, index) => {
          return (
              <div key={index} className="wm-infoCarousel__item" dangerouslySetInnerHTML={{__html: column}}/>
          )
        })}
        </Slider>
        :
        <Redirect to={paths.euInfo} />
      }
      
    </div>
  )
}

class Map extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tooltip_position: {top: -10000, left: -10000},
      tooltip_country: false,
      tooltip_activeMapArea: false
    }
  }

  openTooltip(country, e) {
    if ( this.state.tooltip_activeMapArea ) {
      this.state.tooltip_activeMapArea.classList.remove('-active');
    }
    this.setState({tooltip_position: {left: e.pageX, top: e.pageY}});
    this.setState({tooltip_country: country});
    this.setState({tooltip_activeMapArea: e.target}, () => {
      this.state.tooltip_activeMapArea.classList.add('-active');
    });
  }

  closeTooltip = () => {
    if ( this.state.tooltip_activeMapArea ) {
      this.state.tooltip_activeMapArea.classList.remove('-active');
    }
    this.setState({tooltip_position: {top: -10000, left: -10000}});
    this.setState({tooltip_country: false});
  }

  render() {
    return (
      <div className="wm-map">
        {this.props.page.eu_info_fields.map_image !== undefined ?
              <div className="wm-map__image">
                <img src={this.props.page.eu_info_fields.map_image} width="1317" height="701" border="0" alt="" useMap="#europa_map_webb_Map"/>
                <svg /* viewBox="0 0 100 100" */>
                  {this.props.page.eu_info_fields.eu_countries.map( (country, index) => {
                    return(
                      <polygon tabIndex="0" key={index} shape="poly" alt="" onClick={(e) =>{this.openTooltip(country, e)}}  points={country.coords}/> //data-tip={this.constructDataTip(country)} data-event='click'
                    );
                  })}
                </svg>
                
              </div>
              :
              <Redirect to={paths.euInfo} />
  
        }
        <div className="wm-map__imageTooltip" style={{top: this.state.tooltip_position.top+'px', left: this.state.tooltip_position.left+'px'}}>
          <button onClick={this.closeTooltip}><i class="fal fa-times"></i></button>
          <h4>{this.state.tooltip_country.name}</h4>
            <ul>
              <li><b>{this.props.t('capital')}:</b> {this.state.tooltip_country.capital}</li>
              <li><b>{this.props.t('languages')}:</b> {this.state.tooltip_country.languages}</li>
              <li><b>{this.props.t('joinedEU')}:</b> {this.state.tooltip_country.joined_eu}</li>
              <li><b>{this.props.t('currency')}:</b> {this.state.tooltip_country.currency}</li>
              <li><b>{this.props.t('schengen')}:</b> {this.state.tooltip_country.schengen}</li>
            </ul>
        </div>
      </div>
    )
  }
}

class Modals extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      modalTitle: '',
      modalContent: '',
      activeModalLink: false
    }

  }

  openModal(modal, e) {
    if ( this.state.activeModalLink ) {
      this.state.activeModalLink.classList.remove('-open');
    }
    this.setState({activeModalLink: e.target}, () => {
      this.state.activeModalLink.classList.add('-open');
    });
    this.setState({modalOpen: true});
    this.setState({modalTitle: modal.title});
    this.setState({modalContent: modal.content});
  }

  closeModal = () => {
    if ( this.state.activeModalLink ) {
      this.state.activeModalLink.classList.remove('-open');
    }
    this.setState({modalOpen: false});
  }

  render() {
    return (
      <div className="wm-infoModals">
        <p className="wm-infoModals__introText">{this.props.page.eu_info_fields.modals_intro_text}</p>
        {this.props.page.eu_info_fields.modals !== undefined ?
          <ul className="wm-infoModals__items">
          {this.props.page.eu_info_fields.modals.map( (modal, index) => {
            return (
              <li className="wm-infoModals__itemsItem" key={index} ><button onClick={(e) => { this.openModal(modal, e)}}>{modal.title}</button></li>
            )
          })}
          </ul>
        :
        <Redirect to={paths.euInfo} />
        }
        <div class={`wm-infoModals__modal ${ this.state.modalOpen ? '-open' : ''}`}>
              <button onClick={this.closeModal}><i className="fal fa-times"></i></button>
              <h3>{this.state.modalTitle}</h3>
              <div dangerouslySetInnerHTML={{__html: this.state.modalContent}}/>
        </div>
      </div>

    )
  }
  
}

function Buttons(props) {

  return(
    <div className="wm-infoButtons">
      {props.page.eu_info_fields.buttons !== undefined ?
        <div className="wm-infoButtons__columns">
          {props.page.eu_info_fields.buttons.map( (button, index) => {
            if ( button.link ) {
              return (
                <div className="wm-infoButtons__columnsColumn" key={index}>
                  <h2 className="wm-infoButtons__columnsColumnHeading">{button.heading}</h2>
                  <MoreInfo hideBubble={true} link={button.link} size={200}/>
                </div>
              )
            }
            return '';
            
          })}
        </div>
        :
        <Redirect to={paths.euInfo} />
        }
    </div>
  )
}