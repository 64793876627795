import './styles/layout.scss';

export function Layout(props) {

    return (
        <div className={`wm-mainContainer -bg${props.bg} ${props.mobile ? ' -mobile' : ''}`}>
            {props.children}
        </div>
    );

}

export function Content(props) {

    return (
        <div className="wm-content">
            {props.children}
        </div>
    );

}

export function Header(props) {

    return (
        <div className="wm-header">
            {props.children}
        </div>
    );

}

export function Footer(props) {

    return (
        <div className={`wm-footer` + ( props.justify !== undefined ? ` -justify-${props.justify}` : ``) + ( props.absolute !== undefined ? ' -absolute' : '')}>
            {props.children}
        </div>
    );

}

