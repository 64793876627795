import React from 'react';
import { Link } from "react-router-dom";
import { Layout, Header, Content} from '../layout';
import { Button, LanguageSwitcher } from "../components";
//Translations
import { translate } from 'react-switch-lang';

import paths  from '../paths';

import "../styles/views/start.scss";


class Start extends React.Component {

    

    componentDidMount() {

    }
    render() {
        return(
            <Layout bg="1">
                <LanguageSwitcher/>
                <Header>
                </Header>
                <Content>
                    <div className="wm-start">

                        <div className="wm-start__left">
                            <h1>{this.props.t('european')}<br/><span>{this.props.t('union')}</span></h1>
                            <h3 className="wm-u-fw400">{this.props.t('doWeNeed')}</h3>
                        </div>

                        <div className="wm-start__right">
                            <ul className="wm-start__rightNavigation">
                                <li className="wm-start__rightNavigationItem -info"><Button classes="-whiteFill -biggerText"><Link to={paths.euInfo}>{this.props.t('euInfo')}</Link></Button></li>
                                <li className="wm-start__rightNavigationItem -quiz"><Button classes="-whiteFill -biggerText"><Link to={paths.quiz}>{this.props.t('euQuiz')}</Link></Button></li>
                                <li className="wm-start__rightNavigationItem -skvaller"><Button classes="-whiteFill -biggerText"><Link to={paths.latestNews}>{this.props.t('euGossip')}</Link></Button></li>
                            </ul>
                        </div>

                    </div>
                </Content>
            </Layout>
        )
    }
}

export default translate(Start);