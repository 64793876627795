import React from 'react';
import { Link } from "react-router-dom";
import { Layout, Footer, Content } from '../../layout';
import { LoadingSpinner, Button, MoreInfo, LanguageSwitcher } from "../../components";

import { ReactComponent as StartImage } from '../../assets/img/icons/eu-quiz-time.svg'
//Translations
import { translate } from 'react-switch-lang';

import paths, { mobileQuizURL }  from '../../paths';

import "../../styles/views/quiz.scss";

class Quiz extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            index: -1,
            correct: 0
        }
    }

    start = () => {
        console.log('Starting quiz')
        this.setState({index: 0});
        this.setState({correct: 0});
    }

    addCorrect = () => {
        console.log('Adding correct');
        this.setState(prevState => {
            return {correct: prevState.correct + 1}
        });
    }

    nextQuestion = () => {
        this.setState(prevState => {
            return {index: prevState.index + 1}
        });
    }

    renderView() {
        /* return <End quantity={this.props.questions.length} correctQuantity={this.state.correct} start={this.start} t={this.props.t}/> */
        if ( ! this.props.questions ) {
            return <LoadingSpinner/>
        }
        if ( this.state.index === -1 ) {
            return <Start start={this.start} t={this.props.t} mobile={this.props.mobile}/>
        }

        if ( this.state.index === this.props.questions.length ) {
            return <End quantity={this.props.questions.length} correctQuantity={this.state.correct} start={this.start} t={this.props.t}/>
        }

        return(
            <Question q={this.props.questions[this.state.index]} 
                          index={this.state.index} 
                          nextQuestion={this.nextQuestion}
                          addCorrect={this.addCorrect}
                          isLast={this.state.index === this.props.questions.length-1 ? true : false}
                          t={this.props.t}
                          mobile={this.props.mobile}
            /> 
        )
    }

    renderProgress() {
        let questions = [];
        for ( let i = 0; i < this.props.questions.length; i++ ) {
            questions.push(<li key={i} className={`wm-quiz__progressBarDot ${i <= this.state.index ? '-fill' : ''}`}></li>) 
        }
        return(
            <div className="wm-quiz__progress">
                <ul className="wm-quiz__progressBar">
                    {questions}
                </ul>
            </div>
        )
    }

    render() {
        return(
            <Layout bg="1" mobile={this.props.mobile}>
                { this.props.mobile ?
                    ''
                    :
                    <>
                    <LanguageSwitcher/>
                    <Button classes="-rounded -blueFill -absolute">
                        <Link to={paths.base}>{this.props.t('back')}</Link>
                    </Button>
                    </>
                }

                <Content>
                    <div className="wm-quiz">
                        {this.renderView()}
                    </div>
                </Content>
                <Footer justify="center">
                    {this.renderProgress()}
                </Footer>
            </Layout>
        )
    }
}
export default translate(Quiz);




class Question extends React.Component {

    constructor(props) {
        super(props);

        // Timelimit in seconds
        this.timeLimit = 30;

        this.timerInterval = false;
        this.answerTimeout = false;

        this.state = {
            showCorrect: false,
            answered: false,
            time: 0,
        }
    }

    componentDidMount() {
        this.timerTick();
    }

    componentWillUnmount() {
        clearInterval(this.timerInterval);
        clearTimeout(this.answerTimeout);
    }

    nextQuestion = () => {
        this.setState({showCorrect:false});
        this.setState({answered:false});
        this.props.nextQuestion();
        this.timerTick();
    }


    handleAnswer(e = false) {
        console.log('Question answered');
        if ( e ) {
            if ( e.target.getAttribute('data-iscorrect') === 'true' ) {
                this.props.addCorrect();
            }

            e.target.classList.add('-clicked')
        } else {
            console.log('No answer');
        }

        // Stop ticking on answer
        clearInterval(this.timerInterval);

        this.setState({showCorrect: true});
        this.answerTimeout = setTimeout( () => {
            this.setState({answered:true});
        }, 2000 );
    }

    timerTick() {
        this.setState({time:0});
        clearInterval(this.timerInterval);
        this.timerInterval = setInterval( () => {
            if ( this.state.time >= this.timeLimit*100 ) {
                this.handleAnswer();
            } else {
                this.setState(prevState => {
                    return {time: prevState.time + 1}
                });
            }
        }, 10 );
    }

    renderQuestion() {
        return(
            <>
            <div className="wm-quiz__questionMain">
                <h1 className="wm-u-fw400 wm-u-cBlue" style={{textAlign: 'center', textTransform: 'uppercase'}}>{this.props.q.title.rendered}</h1>
                <h4 className="wm-u-fw500" style={{textAlign: 'center'}}>{this.props.q.quiz_question_data.question}</h4>
                <ul className={`wm-quiz__questionOptions ${this.state.showCorrect ? '-showCorrect' : ''} ${this.props.q.quiz_question_data.options.length > 4 ? '-twoColumns' : '' }`}>
                    {this.props.q.quiz_question_data.options.map( (option, index) =>    {
                        return(
                            <li key={index}>
                                <button data-iscorrect={option.is_correct} onClick={(e) => { this.handleAnswer(e) } }>
                                    {option.option}
                                </button>    
                            </li>
                        )
                    })}
                </ul>

            </div>
            {this.renderTimer()}
            </>
        )
    }
    renderTimer() {
        return(
            <div className="wm-quiz__questionTimer">
                <div className="wm-quiz__questionTimerBar">
                    <div className="wm-quiz__questionTimerBarProgress" style={{'width': `${100 - (this.state.time/this.timeLimit)}%`}}>
                    </div>
                </div>
            </div>
        )
    }

    renderInfo() { 
        return(
            <div className="wm-quiz__questionInfo">
                <h1 className="wm-u-fw400 wm-u-cBlue" style={{textAlign: 'center'}}>{this.props.t('didYouKnow')}</h1>
                <div dangerouslySetInnerHTML={{__html: this.props.q.content.rendered}} />
                <Button classes="-blueFill -rounded -wider -bigText wm-u-fw400">
                    <button onClick={this.nextQuestion}>{this.props.isLast ? this.props.t('showResults') : this.props.t('next') }</button>
                </Button>
                {this.props.q.quiz_question_data.more_info && ! this.props.mobile ?
                    <MoreInfo text={this.props.t('moreInfo')} link={this.props.q.quiz_question_data.more_info}/>
                :
                ''
                }
            </div>
        )
    }


    render() {
        return (
            <div className="wm-quiz__question">
                {this.state.answered ? this.renderInfo() : this.renderQuestion() }

            </div>
        );
    }
}


function Start(props) {
    return(
        <div className="wm-quiz__start">
            <StartImage/>
            <h2 className="wm-u-fw400">{props.t('testYourEUSkills')}</h2>
            <Button classes="-blueFill -rounded -bigText -wider">
                <button onClick={props.start}>{props.t('start')}</button>
            </Button>
            { ! props.mobile ? 
                <MoreInfo ltr={true} text={props.t('mobileVersion')} link={mobileQuizURL}/>
                :
                <LanguageSwitcher stay={true}/>
            }
        </div>
    )
}

function End(props) {
    return(
        <div className="wm-quiz__end">
            <h1 className="wm-u-fw400">{props.t('goodWork')} <b>{props.correctQuantity}/{props.quantity}</b> {props.t('questions')}!</h1>
            <table className="wm-quiz__endTable">
                <tbody>
                    <tr>
                        <td><h1>16-20:</h1></td><td>{props.t('16-20')}</td>
                    </tr>
                    <tr>
                        <td><h1>11-15:</h1></td><td>{props.t('11-15')}</td>
                    </tr>
                    <tr>
                        <td><h1>6-10:</h1></td><td>{props.t('6-10')}</td>
                    </tr>
                    <tr>
                        <td><h1>0-5:</h1></td><td>{props.t('0-5')}</td>
                    </tr>
                </tbody>
            </table>
            <Button classes="-blueFill -rounded -bigText -wider">
                <button className="wm-u-fw400" onClick={props.start}>{props.t('tryAgain')}</button>
            </Button>
        </div>
    )
}