import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import axios from 'axios';
//Translations
import {
  setTranslations,
  getLanguage,
  setDefaultLanguage,
  translate,
} from 'react-switch-lang';

import sv from './sv.json';
import fi from './fi.json';

import paths  from './paths';
import { baseAPIURL } from './paths';

// Views
import Start from './views/start';
import EUInformation from './views/eu-information/eu-information';
import EUInformationSingle from './views/eu-information/eu-information-single';
import Quiz from './views/quiz/quiz';
import LatestNews from './views/latest-news/latest-news';

//Styles
import './styles/base.scss';

// vars
/* var BASE_API_URL = 'https://biblioteket.dev.wikstrommedia.fi/wp-json/wp/v2'; */

var EU_INFORMATION_PAGES__ENDPOINT = baseAPIURL+'/eu-information-page?per_page=100';
var QUIZ_QUESTIONS__ENDPOINT       = baseAPIURL+'/quiz-question?per_page=100';

// Set langs
setTranslations({ sv, fi });
setDefaultLanguage('sv');

//App
class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      euInformation: {
        sv: false,
        fi: false,
      },
      quizQuestions: {
        sv: false,
        fi: false
      }
    }

  }

  componentDidMount() {
    console.log('App did mount')

    this.getEuInfoPages();
    this.getQuizQuestions();

  }

  // Get EU-information pages
  getEuInfoPages = async ( lang = 'sv' ) => {
    let queryString = lang === 'sv' ? '' : `&lang=${lang}`;
    axios.get(`${EU_INFORMATION_PAGES__ENDPOINT}${queryString}`)
      .then( (response) =>  {
        let euInformation = {...this.state.euInformation}
        euInformation[lang] = response.data;
        this.setState({euInformation});
        if ( lang === 'sv' ) {
          this.getEuInfoPages('fi');
          return;
        }  
        console.log('Info pages fetched');
      })
      .catch( (error) =>  {
        console.error(error);
      })
      .then(() =>{});
  }

  // Get quiz
  getQuizQuestions = async ( lang = 'sv') => {
    let queryString = lang === 'sv' ? '' : `&lang=${lang}`;
    axios.get(`${QUIZ_QUESTIONS__ENDPOINT}${queryString}`)
      .then( (response) =>  {
        let quizQuestions = {...this.state.quizQuestions}
        quizQuestions[lang] = response.data;
        this.setState({quizQuestions});
        if ( lang === 'sv' ) {
          this.getQuizQuestions('fi');
          return;
        } 
        console.log('Quiz questions fetched');
      })
      .catch( (error) =>  {
        console.error(error);
      })
      .then(() =>{});
  }


  render() {

    return (
        <div className="App">
                <Router>
                  <Switch>
                    <Route path={paths.euInfoSingle}  render={(props)     =>  (<EUInformationSingle {...props} />)} />      
                    <Route path={paths.euInfo}        render={(props)     =>  (<EUInformation {...props} pages={this.state.euInformation[getLanguage()]} />)} />
                    <Route path={paths.quiz}          render={(props)     =>  (<Quiz questions={this.state.quizQuestions[getLanguage()]} />)} />
                    <Route path={paths.latestNews}    render={(props)     =>  (<LatestNews/>)} />
                    <Route path={paths.base}          render={(props)     =>  (<Start {...props} />)} />

                    <Route path="/"                   render={(props)     =>  (<Quiz mobile={true} questions={this.state.quizQuestions[getLanguage()]} />)} />      
                  </Switch>
                </Router>
        </div>
    );
  }

}

export default translate(App);



