
import { Link } from "react-router-dom";
import { translate } from 'react-switch-lang';

import { Header, Footer, Layout, Content } from '../../layout';
import { Button, LoadingSpinner, LanguageSwitcher } from "../../components";

import paths  from '../../paths';

function EUInformation(props) {
    console.log(props.match)
    return(
        
        <Layout bg="1">
            <LanguageSwitcher/>
            <Header>
                <Button classes="-rounded -blueFill">
                    <Link to={paths.base}>{props.t('back')}</Link>
                </Button>
            </Header>
            <Content>
                <h1 style={{textAlign: 'center'}}>{props.t('whatDoYouWant')}</h1>
                {props.pages ?                    
                    <PageList {...props} />
                    :
                    <LoadingSpinner/>
                }
            </Content>
            <Footer>
            </Footer>
        </Layout>
    )
        
}
export default translate(EUInformation);

function PageList(props) {
    return (
            <ul className="wm-infoPages">
                {props.pages.map( (page, index) => {
                    return(
                        <li key={page.id}>
                            <Button classes="-semiRounded -transparentFill -bigText">
                                <Link to={{ pathname: paths.euInfoSingle, state: {page: page} }}>{page.title.rendered}</Link>
                            </Button>
                        </li>
                    )
                })}
            </ul>
    );
}