import React from 'react';
import { setLanguage, getLanguages } from 'react-switch-lang';
import { Link } from "react-router-dom";

import QRCode from "qrcode.react";
import { ReactComponent as BubbleTail } from './assets/img/icons/bubble-tail.svg';

import { ReactComponent as SVFlag } from './assets/img/icons/sv.svg';
import { ReactComponent as FIFlag } from './assets/img/icons/fi.svg'

import paths  from './paths';

import './styles/components.scss';

export function Button(props) {

    return (
        <div className={`wm-button ${props.classes}`}>
            {props.children}
        </div>
    );
}


export function LoadingSpinner() {
    return (
        <div className="wm-loadingSpinner">
            <i className="fas fa-circle-notch"></i>
        </div>
    );
}

export function LanguageSwitcher(props) {
    return (
        <div className="wm-languageSwitcher">
            {getLanguages().map( (lang) =>  {
                let flag = lang === 'sv' ? <SVFlag/> : <FIFlag/>;
                return(
                    <button className={`wm-languageSwitcher__button -${lang}`} key={lang}>
                        {props.stay ? 
                            <span onClick={ () => { setLanguage(lang) } }>{flag}</span>
                            :
                            <Link to={paths.base} onClick={ () => { setLanguage(lang) } }>{flag}</Link>
                        }
                    </button>
                );
            })}
        </div>
    );
}

/* export function Modal(props) {
    return(
        <div className={props.open ? `wm-modal -open` : `wm-modal`}>
            <Button><button onClick={props.close}>X</button></Button>
            <div dangerouslySetInnerHTML={{__html: props.content}}/>
        </div>
    );
} */

/* export class Popup extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false
        }
    }
    
    render() {
        return(
            <div className={`wm-popup` + ( this.state.open ? ` -open` : ``) + ( this.props.iframe ? ` -iframe` : ``)}>
                <div className="wm-popup__link">
                    <Button classes={this.props.classes}>
                        <button onClick={() => {this.setState({open:true})}}>{this.props.link}</button>
                    </Button>
                </div>
                {this.state.open ?
                    <div className="wm-popup__popup">
                        <button onClick={() => {this.setState({open:false})}}><i className="fal fa-times"></i></button>
                        <div dangerouslySetInnerHTML={{__html: this.props.content}}/>
                    </div>
                    :
                    ''
                }
            </div>
        )
    }
} */

export function MoreInfo(props) {
    let size = props.size === undefined ? 100 : props.size;
    return (
        <div className={`wm-moreInfo ${props.ltr ? '-ltr' : ''}`}>
            { ! props.hideBubble ?
                <div className="wm-moreInfo__bubble">
                    <b>{props.text}</b>
                    <BubbleTail/>
                </div>
                :
                ''
            }

            <div className="wm-moreInfo__qr">
                {props.link !== undefined ?
                    <QRCode value={props.link} size={size} imageSettings={{src:props.link, width: size, height: size}}/>
                    :
                    ''
                }
            </div>
        </div>
    );
}
